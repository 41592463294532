<template>
  <b-modal
    visible
    scrollable
    centered
    size="xl"
    hide-footer
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div
      class="patient-detail"
    >
      <div class="crm-tab-row">
        <div
          v-for="(tab) in filteredTabs"
          :ref="`tab-${tab.index}`"
          :key="tab.index"
          class="crm-tab-item"
          :class="{ active: tab.index === activeTab}"
          @click="clickToTab(tab.index)"
        >
          {{ tab.text }}
        </div>
      </div>
      <div
        class="crm-tab-detail"
        :style="{ maxHeight : activeTab === 0 ? '700px' : '' }"
      >
        <Loading
          v-if="isLoading"
          class="loading-wrapper"
        />
        <template v-else>
          <PatientDetailPerson
            v-if="activeTab === 0"
            :person="patient"
          />
          <PatientDetailConsultations
            v-if="activeTab === 1"
            :patient-id="patient.id"
          />
          <PatientDetailPolicies
            v-if="activeTab === 2"
            :policies="patient.policies"
            :person-id="patient.id"
            :patient-info="patientInfo"
          />
          <PatientDetailAppeals
            v-if="activeTab === 3"
            :patient-id="patient.id"
            :patient="patient"
            @open-chat-modal="openChatModalFromAppeal"
          />
          <PatientDetailChat
            v-if="activeTab === 4"
            :patient="patient"
            :chat-appeal-id="appeal?.chatAppealId"
          />
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';
import PatientDetailPerson from '@/components/Patients/PatientDetailPerson';
import PatientDetailPolicies from '@/components/Patients/PatientDetailPolicies';
import PatientDetailConsultations from '@/components/Patients/PatientDetailConsultations';
import PatientDetailAppeals from '@/components/Patients/PatientDetailAppeals';
import PatientDetailChat from '@/components/Patients/PatientDetailChat';
import Loading from '@/components/Loading';

export default {
  name: 'PatientDetailModal',
  components: {
    PatientDetailPerson,
    PatientDetailPolicies,
    PatientDetailConsultations,
    PatientDetailAppeals,
    PatientDetailChat,
    Loading,
  },
  mixins: [mixinRoles],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      insuranceProgramms: [],
      tabs: [
        {
          index: 0,
          text: 'Информация о пациенте',
          access: true,
        },
        {
          index: 1,
          text: 'Консультации',
          access: this.checkFeatureAccess({ name: 'Информация о пациенте - консультации', url: '/patients' }),
        },
        {
          index: 2,
          text: 'Страховые полисы',
          access: true,
        },
        {
          index: 3,
          text: 'Обращения',
          access: this.checkFeatureAccess({ name: 'Информация о пациенте - обращения', url: '/patients' }),
        },
        {
          index: 4,
          text: 'Чат',
          access: this.checkFeatureAccess({ name: 'Информация о пациенте - Чат', url: '/patients' }),
        },
      ],
      patient: null,
      activeTab: 0,
      appeal: null,
    };
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter((tab) => tab.access);
    },
  },
  async created() {
    this.isLoading = true;
    try {
      if (this.$store.state.Patients.activePolicyId) {
        this.activeTab = 2;
      }

      await this.getPatient({ manageLoading: false });
      await this.getInsuranceCompanies();
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }

    Bus.$on('patient:update', this.getPatient);
    Bus.$on('set-patient-load', this.setLoad);
  },
  beforeDestroy() {
    Bus.$off('patient:update', this.getPatient);
    Bus.$off('set-patient-load', this.setLoad);
    this.$store.commit(this.$types.INDEMNITY_PERSON_ID_SET, null);
  },
  methods: {
    async getPatient({ manageLoading } = { manageLoading: true }) {
      try {
        if (manageLoading) this.isLoading = true;
        const patient = await this.$store.dispatch(this.$types.PATIENT_FETCH, this.patientId);
        const deviceId = await this.$store.dispatch(this.$types.GET_USER_DEVICE_ID, this.patientId);
        this.patient = { ...patient, deviceId };
        this.$store.commit(this.$types.INDEMNITY_PERSON_ID_SET, patient.id);
      } finally {
        if (manageLoading) this.isLoading = false;
      }
    },
    async getInsuranceCompanies() {
      const insuranceCompanies = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
      this.$store.commit(this.$types.INSURANCE_COMPANIES_SET, insuranceCompanies);
    },
    setLoad(value) {
      this.isLoading = value;
    },
    openChatModalFromAppeal(appeal) {
      if (appeal) this.activeTab = 4;
      this.appeal = appeal;
    },
    clickToTab(idx) {
      if (this.appeal && this.activeTab === 4) {
        this.appeal.chatAppealId = null;
      }
      this.activeTab = idx;
    },
    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-detail {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.crm-tab-row {
  display: flex;
  width: 100%;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  overflow: hidden;
}

::v-deep.crm-tab-item {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  min-height: 60px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  background-color: #fff;

  &.active {
    background: #6E88F3;
    color: #ffffff;
  }
}
::v-deep.crm-tab-item + .crm-tab-item {
  border-left: 1px solid #E0E0E0;
}
.crm-tab-detail {
  width: 100%;
  margin-top: 10px;
  height: calc(100vh - 250px);
}
</style>
